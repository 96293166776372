import React, { useContext, useState } from 'react';
import {
  Avatar,
  Badge,
  Button,
  Col,
  COLORS,
  ConfigProvider,
  Flex,
  FONTS,
  Row,
  SPACING,
  Tag,
  ThemeConfig,
  Typography,
} from '@optii-solutions/ui-library';
import { useTranslation, TFunction } from 'react-i18next';
import { EyeFilled } from '@ant-design/icons';
import { Message, Participant } from '@twilio/conversations';
import { UserAccessContext } from '@optii/shared';
import { Dayjs } from 'dayjs';
import { ChatContext } from '../../context/chat.context';
import { useOnlineStatus } from '../../hooks/useOnlineStatus';
import { useMessage } from '../../hooks/useMessage';
import handleMessageContent from './handleMessageContent';
import Status from './Status';
import { MessageFilePreview } from './MessageFilePreview';
import { FileList } from '../../types';

type MessageItemProps = {
  message: Message;
  authorName: string;
  isOptii?: boolean;
  timestamp: Dayjs;
  participants: Participant[] | undefined;
};

const THEME: ThemeConfig = {
  components: {
    Typography: {
      titleMarginTop: SPACING.NONE,
      titleMarginBottom: SPACING.NONE,
    },
    Badge: {
      dotSize: SPACING.SIZE_DEFAULT,
    },
  },
};

const CONTENT_THEME: ThemeConfig = {
  components: {
    Typography: {
      fontSize: FONTS.small.size,
      colorText: COLORS.neutral[8],
    },
    Button: {
      paddingInline: SPACING.NONE,
      colorText: COLORS.primary[5],
      defaultBorderColor: 'transparent',
      controlHeight: undefined,
      defaultShadow: 'transparent',
      fontSize: FONTS.xSmall.size,
      fontWeight: 500,
      borderRadius: 0,
      lineWidth: 0,
      defaultHoverBorderColor: 'transparent',
      defaultHoverColor: COLORS.primary[6],
      paddingBlock: SPACING.NONE,
    },
  },
};

function UniqueText({ t }: { t: TFunction<['chat'], undefined> }) {
  const UNIQUE_TEXT_THEME: ThemeConfig = {
    token: {
      fontSize: FONTS.xSmall.size,
      lineHeight: FONTS.xSmall.lineHeight,
      colorText: COLORS.neutral[6],
    },
  };
  return (
    <ConfigProvider theme={UNIQUE_TEXT_THEME}>
      <Typography.Text
        style={{
          marginLeft: SPACING.SIZE_XXL,
        }}
      >
        <EyeFilled /> {t('chat:Only visible to you')}
      </Typography.Text>
    </ConfigProvider>
  );
}

function AdminTag({ t }: { t: TFunction<['chat'], undefined> }) {
  const ADMIN_TAG_THEME: ThemeConfig = {
    token: {
      fontSize: FONTS.xSmall.size,
      colorText: COLORS.neutral[8],
    },
    components: {
      Tag: {
        colorBorder: 'transparent',
        marginXS: SPACING.NONE,
      },
    },
  };
  return (
    <Tag
      theme={ADMIN_TAG_THEME}
      style={{
        letterSpacing: FONTS.xSmall.letterSpacing,
        paddingInline: SPACING.SIZE_XXS,
        lineHeight: FONTS.xSmall.lineHeight,
      }}
    >
      <Typography.Text>{t('chat:APP')} </Typography.Text>
    </Tag>
  );
}

function Timestamp({ time }: { time?: string }) {
  const TIMESTAMP_THEME: ThemeConfig = {
    components: {
      Typography: {
        colorText: COLORS.neutral[7],
        fontSize: FONTS.xSmall.size,
      },
    },
  };
  return (
    <ConfigProvider theme={TIMESTAMP_THEME}>
      <Typography.Text style={{ whiteSpace: 'nowrap' }}>{time}</Typography.Text>
    </ConfigProvider>
  );
}

export function MessageItem({
  message,
  authorName,
  isOptii,
  timestamp,
  participants,
}: MessageItemProps) {
  const { client } = useContext(ChatContext);
  const { user } = useContext(UserAccessContext.Context);
  const { originalMessage, translatedMessage, canTranslate } = useMessage(
    message,
    user?.preferredLang === 'en-GB' ? 'en' : user?.preferredLang,
  );
  const { status } = useOnlineStatus(client, message.author as string);
  const { attachments = [] } = message.attributes as {
    attachments: FileList[];
  };

  const { t } = useTranslation(['chat']);
  const [viewTranslation, setViewTranslation] = useState(true);

  return (
    <ConfigProvider theme={THEME}>
      {isOptii && <UniqueText t={t} />}
      <Flex
        align="flex-start"
        justify="space-between"
        gap={SPACING.SIZE_SM}
        style={{
          padding: `${SPACING.SIZE_DEFAULT}px 0`,
        }}
      >
        <Row gutter={SPACING.SIZE_SM} wrap={false}>
          <Col>
            <Badge dot={status} color={COLORS.polarGreen[5]} offset={[-4, 26]}>
              <Avatar
                style={{
                  verticalAlign: 'center',
                }}
                size="default"
                gap={4}
              >
                {authorName?.charAt(0)}
              </Avatar>
            </Badge>
          </Col>
          <Col>
            <Flex gap={SPACING.SIZE_SM} align="center">
              <Typography.Text
                style={{
                  marginTop: SPACING.NONE,
                  fontSize: FONTS.small.size,
                  fontWeight: 700,
                  letterSpacing: FONTS.small.letterSpacing,
                  lineHeight: FONTS.small.lineHeight,
                }}
              >
                {authorName}
              </Typography.Text>
              {isOptii && <AdminTag t={t} />}

              <Timestamp time={timestamp?.format('LT')} />
              {message.author === user?.id ? (
                <Status
                  message={message}
                  participants={participants || []}
                  loggedUserId={user?.id ?? '0'}
                />
              ) : null}
            </Flex>

            <Flex vertical align="flex-start" gap={SPACING.SIZE_XS}>
              <ConfigProvider theme={CONTENT_THEME}>
                <Typography.Paragraph
                  style={{
                    maxWidth: '4000px',
                    marginBottom: SPACING.NONE,
                  }}
                >
                  {viewTranslation
                    ? handleMessageContent(translatedMessage)
                    : handleMessageContent(originalMessage)}
                </Typography.Paragraph>
                {canTranslate ? (
                  <Button onClick={() => setViewTranslation((prev) => !prev)}>
                    {viewTranslation
                      ? t('chat:See original')
                      : t('chat:See translation')}
                  </Button>
                ) : null}
              </ConfigProvider>
              <MessageFilePreview fileList={attachments} />
            </Flex>
          </Col>
        </Row>
        {isOptii && <Button type="default">Add Job</Button>}
      </Flex>
    </ConfigProvider>
  );
}

import i18n from "i18n";

const translatedConstants = {};
export default translatedConstants;

const getJobTypes = _ => ({
  guest: i18n.t("jobs:guest"),
  internal: i18n.t("jobs:internal")
});

const getDurations = _ => [
  { id: "00:05:00", displayName: `5 ${i18n.t("common:mins")}` },
  { id: "00:10:00", displayName: `10 ${i18n.t("common:mins")}` },
  { id: "00:15:00", displayName: `15 ${i18n.t("common:mins")}` },
  { id: "00:30:00", displayName: `30 ${i18n.t("common:mins")}` },
  { id: "00:45:00", displayName: `45 ${i18n.t("common:mins")}` },
  { id: "01:00:00", displayName: `1 ${i18n.t("common:hr")}` },
  { id: "01:30:00", displayName: `1.5 ${i18n.t("common:hrs")}` },
  { id: "02:00:00", displayName: `2 ${i18n.t("common:hrs")}` },
  { id: "04:00:00", displayName: `4 ${i18n.t("common:hrs")}` },
  { id: "06:00:00", displayName: `6 ${i18n.t("common:hrs")}` },
  { id: "08:00:00", displayName: `8 ${i18n.t("common:hrs")}` },
  { id: "10:00:00", displayName: `10 ${i18n.t("common:hrs")}` },
  { id: "12:00:00", displayName: `12 ${i18n.t("common:hrs")}` },
  { id: "1.00:00:00", displayName: `24 ${i18n.t("common:hrs")}` },
  { id: "2.00:00:00", displayName: `48 ${i18n.t("common:hrs")}` },
  { id: "3.00:00:00", displayName: `72 ${i18n.t("common:hrs")}` }
];

const getOffsetTypes = _ => [
  // { id: "OnDueAt", displayName: "on due time" },
  { id: "BeforeDueAt", displayName: i18n.t("jobs:before due time") },
  { id: "AfterDueAt", displayName: i18n.t("jobs:after due time") }
];

const getAssignStatus = _ => ({
  assigned: i18n.t("jobs:assigned"),
  unassigned: i18n.t("jobs:unassigned")
});

function updateTranslations() {
  translatedConstants.assignStatus = getAssignStatus();
  translatedConstants.jobTypes = getJobTypes();
  translatedConstants.durations = getDurations();
  translatedConstants.offsetTypes = getOffsetTypes();
}

// i18next seems ready -> initial fill translations
if (i18n.isInitialized) {
  updateTranslations();
}

// reset translations to new values on language change
i18n.on("languageChanged", (lng) => {
  updateTranslations();
});

// we loaded some translation file? reset needed?!?
// https://www.i18next.com/overview/api#store-events
i18n.on("loaded", (lng) => {
  updateTranslations();
});

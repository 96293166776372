import { useCallback, useEffect, useState } from 'react';
import { Client } from '@twilio/conversations';

export function useOnlineStatus(client: Client | null, id: string) {
  const [status, setStatus] = useState(false);

  const getUserData = useCallback(async () => {
    const userInfo = await client?.getUser(id);

    setStatus(userInfo?.isOnline || false);
  }, [client, id]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  return {
    status,
  };
}

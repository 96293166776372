import {
  Conversation,
  Message,
  Paginator,
  Participant,
} from '@twilio/conversations';
import { FileList, MessageStatus } from '../types';

const VIDEO_EXTENSIONS = ['.mp4', '.avi', '.mov', '.webm', 'ogg'];
const IMAGE_EXTENSIONS = [
  '.jpg',
  '.png',
  '.jpeg',
  '.gif',
  '.svg',
  '.JPG',
  '.PNG',
  '.JPEG',
  '.GIF',
  '.SVG',
];

type GetMessageStatusPromise = Promise<{
  [MessageStatus.Delivered]?: number;
  [MessageStatus.Read]?: number;
  [MessageStatus.Failed]?: number;
  [MessageStatus.Sending]?: number;
}>;

export async function getMessageStatus(
  message: Message,
  participants: Participant[],
  loggedUserId: string,
): GetMessageStatusPromise {
  const statuses = {
    [MessageStatus.Delivered]: 0,
    [MessageStatus.Read]: 0,
    [MessageStatus.Failed]: 0,
    [MessageStatus.Sending]: 0,
  };

  if (message.index === -1) {
    return Promise.resolve({
      ...statuses,
      [MessageStatus.Sending]: 1,
    });
  }

  participants.forEach((participant) => {
    if (participant.identity === loggedUserId || participant.type !== 'chat') {
      return;
    }

    if (
      participant.lastReadMessageIndex &&
      participant.lastReadMessageIndex >= message.index
    ) {
      statuses[MessageStatus.Read] += 1;
    } else if (participant.lastReadMessageIndex !== -1) {
      statuses[MessageStatus.Delivered] += 1;
    }
  });

  if (message.aggregatedDeliveryReceipt) {
    const receipts = await message.getDetailedDeliveryReceipts(); // paginated backend query every time

    receipts.forEach((receipt) => {
      if (receipt.status === 'read') {
        statuses[MessageStatus.Read] += 1;
      }

      if (receipt.status === 'delivered') {
        statuses[MessageStatus.Delivered] += 1;
      }

      if (receipt.status === 'failed' || receipt.status === 'undelivered') {
        statuses[MessageStatus.Failed] += 1;
      }

      if (receipt.status === 'sent' || receipt.status === 'queued') {
        statuses[MessageStatus.Sending] += 1;
      }
    });
  }

  return statuses;
}

export async function getAllConversationList(
  subscribedConversations?: Paginator<Conversation>,
  hasNextPage?: boolean,
  existingConversations: Conversation[] = [],
) {
  const allConversations = existingConversations.concat(
    subscribedConversations?.items || [],
  );

  if (!hasNextPage) {
    return allConversations;
  }

  const nextPageConversation = await subscribedConversations?.nextPage();

  return getAllConversationList(
    nextPageConversation,
    nextPageConversation?.hasNextPage,
    allConversations,
  );
}

export const isFileExtensionImageOrVideo = (name: string) => {
  const FILE_NAME_MATCH_REGEX =
    name.match(/\.\w{3,4}($)/) && name.match(/\.\w{3,4}($)/)?.[0];

  if (FILE_NAME_MATCH_REGEX) {
    const isVideo = VIDEO_EXTENSIONS.includes(FILE_NAME_MATCH_REGEX);

    const isImage = IMAGE_EXTENSIONS.includes(FILE_NAME_MATCH_REGEX);

    return {
      isVideo,
      isImage,
    };
  }

  return {
    isVideo: false,
    isImage: false,
  };
};

export const generateBase64ImageFromVideo = (url: string) =>
  new Promise<string>((resolve) => {
    const video = document.createElement('video');
    video.src = url;
    video.crossOrigin = 'anonymous';
    video.playsInline = true;
    video.onloadedmetadata = () => {
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext('2d');

      video.currentTime = 0;
      video.onseeked = () => {
        context?.drawImage(video, 0, 0, canvas.width, canvas.height);

        const base64Image = canvas.toDataURL('image/png');
        resolve(base64Image);
      };
    };
  });

export const separateImagesAndTextAttachments = (attachments: FileList[]) => {
  const mediaAttachments: FileList[] = [];
  const textAttachments: FileList[] = [];

  attachments.forEach((attachment) => {
    const { isVideo, isImage } = isFileExtensionImageOrVideo(attachment.name);

    if (isVideo || isImage) mediaAttachments.push(attachment);
    else textAttachments.push(attachment);
  });

  return {
    mediaAttachments,
    textAttachments,
  };
};

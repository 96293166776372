import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Flex, Input, Modal, SPACING } from '@optii/ui-library';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { UserAccessContext } from '@optii/shared/index';
import { ChatContext } from '../../context/chat.context';
import { EmployeeOption } from './EmployeeOption';
import { debounce } from '../../utils/debounce';
import { SelectOption } from '../message/types';

type SelectEmployeeModalProps = {
  open: boolean;
  onClose: () => void;
};

export function SelectEmployeeModal({
  open,
  onClose,
}: SelectEmployeeModalProps) {
  const { t } = useTranslation(['chat', 'common']);
  const { user } = useContext(UserAccessContext.Context);
  const { client, employees, channels, setChannels, mapPrivateConversation } =
    useContext(ChatContext);

  const [search, setSearch] = useState('');

  const filterAvailableTeamMembers = (employee: SelectOption) =>
    search
      ? employee.label
          .toLowerCase()
          .trim()
          .indexOf(search.toLowerCase().trim()) !== -1
      : true;

  const { privateChannels } = channels;
  const history = useHistory();

  const availableEmployeeOptions = useMemo(
    () =>
      employees
        .filter(
          (employee) =>
            !privateChannels
              .map((privateChannel) => privateChannel.label)
              .includes(employee.label),
        )
        .filter((employee) => employee.value !== user?.id),
    [privateChannels, employees, user],
  );

  function onSearchTeamMembers(e: React.ChangeEvent<HTMLInputElement>) {
    setSearch(e.target.value);
  }

  const debouncedSearchTeamMembers = debounce<
    React.ChangeEvent<HTMLInputElement>
  >(onSearchTeamMembers, 500);

  const onCreateNewChannel = useCallback(
    async (employeeId: string) => {
      if (!client) return undefined;

      const { identity } = client.user;
      const newChannel = await client.createConversation({
        friendlyName: `${identity}_${employeeId}`,
        uniqueName: `${identity}_${employeeId}`,
      });

      await newChannel.join();
      await newChannel.add(employeeId);

      const formattedChannel = await mapPrivateConversation(
        newChannel,
        employees,
        user?.id,
      );
      if (formattedChannel) {
        setChannels(({ privateChannels: directChannels, publicChannels }) => ({
          publicChannels,
          privateChannels: directChannels
            .concat(formattedChannel)
            .sort((a, b) => {
              if (a.label && b.label) return a.label.localeCompare(b.label);
              return 0;
            }),
        }));
      }
      history.push(newChannel.sid);
      onClose();
      return newChannel;
    },
    [
      client,
      history,
      onClose,
      employees,
      mapPrivateConversation,
      user?.id,
      setChannels,
    ],
  );

  return (
    <Modal
      title={t('chat:Select Team Member')}
      open={open}
      forceRender
      footer={null}
      onCancel={onClose}
      onClose={onClose}
    >
      <Input
        placeholder={t('chat:Search Team Members')}
        onChange={debouncedSearchTeamMembers}
        style={{
          marginBottom: SPACING.SIZE_MD,
        }}
      />
      <Flex
        align="center"
        vertical
        gap={SPACING.SIZE_MD}
        style={{
          maxHeight: 500,
          overflow: 'auto',
        }}
      >
        {availableEmployeeOptions
          .filter(filterAvailableTeamMembers)
          .map(({ label, value }) => (
            <EmployeeOption
              label={label}
              onCreateNewChannel={onCreateNewChannel}
              value={value}
              client={client}
              key={value}
            />
          ))}
      </Flex>
    </Modal>
  );
}

import { useContext } from 'react';

import {
  PropertyContext,
  UserAccessContext,
  EmploymentContext,
} from '@optii/shared';
import { sortByDisplayName } from '@optii/shared/utils/sort';

export function useAccess() {
  const { access } = useContext(UserAccessContext.Context);
  const { property } = useContext(PropertyContext.Context);
  const { employee } = useContext(EmploymentContext.Context);
  const properties = access && access.Access && access.Access.Properties;
  const currentProperty =
    properties &&
    property &&
    properties.find((prop) => prop.id === property.id);

  const roles = currentProperty && currentProperty.Roles;

  const userPermissions = [];
  roles &&
    roles.forEach(
      (role) => role.Permissions && userPermissions.push(...role.Permissions),
    );

  // Given a list of permissions required, check to see if the user can
  // params: permsToCheck - an array of permissions required
  //     yes - the truth case, can be undefined (will return true), a function to express and return, or any plain value/object,
  //     no - same as yes, but the false case (undefined will return false)
  // eslint-disable-next-line no-shadow
  function can(userPermissions, permsToCheck, yes = true, no = false) {
    let allowed = true;
    // given either a permission or a permission preface with a *, check if they have permission
    function hasPerm(toCheck) {
      const index = toCheck.indexOf('*');
      if (index !== -1) {
        return userPermissions.find((item) =>
          item.startsWith(toCheck.substring(0, index)),
        );
      }
      return userPermissions.includes(toCheck);
    }
    if (permsToCheck) {
      if (!Array.isArray(permsToCheck)) {
        allowed = hasPerm(permsToCheck);
      } else {
        // eslint-disable-next-line no-restricted-syntax
        for (const perm of permsToCheck) {
          if (!hasPerm(perm)) {
            allowed = false;
            break;
          }
        }
      }
    }

    function applyResult(fx) {
      switch (typeof fx) {
        case 'function':
          return fx();
        default:
          return fx;
      }
    }
    // FIXME: Useful for ignoring permissions in devlopment, must remove
    // return applyResult(yes);
    return allowed ? applyResult(yes) : applyResult(no);
  }

  // Find all employement details at current property
  const empCurrentProp =
    employee &&
    employee.propertyDetails &&
    employee.propertyDetails.find(
      (prop) => Number(prop.propertyId) === Number(property.id),
    );

  const positions = empCurrentProp && empCurrentProp.roles;

  function canInDepartment(permsToCheck, departmentId, yes, no) {
    // if has role with permission in positions with specified department

    const rolesFromPosDpt =
      positions &&
      positions
        .filter((pos) => Number(pos.deptId) === Number(departmentId))
        .map((pos) => pos.roleId);

    const matchingRoles =
      roles &&
      rolesFromPosDpt &&
      roles.filter((role) => rolesFromPosDpt.includes(Number(role.id)));

    const matchingPermissions = [];
    matchingRoles &&
      matchingRoles.forEach((role) =>
        matchingPermissions.push(...role.Permissions),
      );

    return can(matchingPermissions, permsToCheck, yes, no);
  }
  function getDepartmentAccess(rolesForDepartment) {
    const poss = positions && positions.map((pos) => pos.roleId);
    if (!poss) return [];
    const depts = rolesForDepartment.filter((el) =>
      poss.find((posId) => el.id === String(posId)),
    );
    const ids = [];
    const result = depts
      .map((prev) => prev.accessibleDepts)
      .flat()
      .map((prev) => ({
        id: prev.departmentId.toString(),
        displayName: prev.departmentName,
      }))
      .filter((item) => {
        // remove duplicates
        if (ids.includes(item.id)) {
          return false;
        }
        ids.push(item.id);
        return true;
      });

    return sortByDisplayName(result);
  }
  // Get the departments from the positions for ease
  const departments = {};
  positions &&
    positions.forEach((pos) => {
      departments[pos.deptId] = { id: pos.deptId, displayName: pos.deptName };
    });

  const posDepartments =
    departments && Object.keys(departments).map((dept) => departments[dept]);

  // eslint-disable-next-line no-shadow
  function departmentsByRoleAndPosition(roles) {
    // Need to do this because we get ints and strings interchangably for IDs from BE.
    const posDepartmentsFormatted =
      posDepartments &&
      posDepartments.map((dep) => ({
        id: String(dep.id),
        displayName: dep.displayName,
      }));

    const departmentAccess = getDepartmentAccess(roles);
    let deptOptions = [];

    // Handle use case where they have roles with no department access
    if (posDepartmentsFormatted && !departmentAccess) {
      deptOptions = sortByDisplayName([...posDepartmentsFormatted]);
    } else {
      deptOptions = sortByDisplayName([
        ...posDepartmentsFormatted,
        ...departmentAccess,
      ]);
    }

    // Create an array from a Set (which enforced uniqueness)
    return Array.from(new Set(deptOptions.map((s) => s.id))).map((id) => ({
      id,
      displayName: deptOptions.find((d) => d.id === id).displayName,
    }));
  }

  return {
    can: can.bind(this, userPermissions),
    canInDepartment,
    userPermissions,
    properties,
    positions,
    getDepartmentAccess,
    departmentsByRoleAndPosition,
    departments: posDepartments,
    employee,
  };
}

import React, {
  CSSProperties,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Card,
  COLORS,
  ConfigProvider,
  Divider,
  Flex,
  FONTS,
  Grid,
  List,
  RADIUS,
  Skeleton,
  SPACING,
  Spin,
  ThemeConfig,
  Typography,
} from '@optii-solutions/ui-library';
import { LeftOutlined, Loading3QuartersOutlined } from '@ant-design/icons';
import {
  Conversation,
  Message,
  Paginator,
  Participant,
} from '@twilio/conversations';
import dayjs, { Dayjs } from 'dayjs';
import InfiniteScroll from 'react-infinite-scroll-component';
// import VirtualList from 'rc-virtual-list'; In case we require virtualization, this would be the alternative to the InfiniteScroll
import { UserAccessContext } from '@optii/shared/index';
import { MessageInput } from './MessageInput';
import { ChatContext } from '../../context/chat.context';
import { MessageItem } from './Message';
import { SelectOption } from './types';

const LIST_STYLE: CSSProperties = {
  maxHeight: '69vh',
  minHeight: '450px',
  overflowY: 'auto',
  overflowX: 'hidden',
  marginBottom: SPACING.SIZE_MD,
  overflowAnchor: 'none',
  flexDirection: 'column-reverse',
};

const THEME: ThemeConfig = {
  components: {
    List: {
      itemPadding: String(SPACING.SIZE_DEFAULT),
    },
    Card: {
      paddingLG: SPACING.SIZE_MD,
    },
  },
};

const CARD_TITLE_THEME: ThemeConfig = {
  token: {
    fontFamily: 'Roboto',
    colorText: COLORS.neutral[9],
    fontSize: FONTS.large.size,
  },
};

type RenderItemProps = {
  item: Message;
  employees: SelectOption[];
  index: number;
  array?: Message[];
  participants: Participant[] | undefined;
};

function DateSeparator({ date }: { date: Dayjs }) {
  const DATE_THEME: ThemeConfig = {
    components: {
      Divider: {
        textPaddingInline: SPACING.NONE,
        margin: SPACING.NONE,
      },
      Typography: {
        fontSize: FONTS.xSmall.size,
        colorText: COLORS.neutral[9],
      },
    },
  };
  return (
    <ConfigProvider theme={DATE_THEME}>
      <Divider
        plain
        style={{
          marginBottom: SPACING.SIZE_DEFAULT,
        }}
      >
        <Flex
          style={{
            border: `1px solid ${COLORS.neutral[5]}`,
            background: COLORS.neutral[1],
            borderRadius: RADIUS.RADIUS_XL,
            padding: `${SPACING.SIZE_XS}px ${SPACING.SIZE_MS}px`,
          }}
        >
          <Typography.Text style={{ fontWeight: 500 }}>
            {date.format('LL')}
          </Typography.Text>
        </Flex>
      </Divider>
    </ConfigProvider>
  );
}

function RenderItem({
  item,
  employees,
  index,
  array,
  participants,
}: RenderItemProps) {
  const { dateCreated, author } = item;
  const timestamp = dayjs(dateCreated);
  const todayTimestamp =
    array && dayjs(array[index - 1]?.dateCreated).format('LL');

  const authorName = employees.find(({ value }) => value === author)?.label;
  return authorName && author ? (
    <Fragment key={item.sid}>
      {index === 0 || timestamp.format('LL') !== todayTimestamp ? (
        <DateSeparator date={timestamp} />
      ) : null}
      <List.Item key={item.sid}>
        <MessageItem
          key={item.sid}
          message={item}
          authorName={authorName}
          participants={participants}
          timestamp={timestamp}
          isOptii={false}
        />
      </List.Item>
    </Fragment>
  ) : null;
}

export function MessageList() {
  const { employees, getChannelById } = useContext(ChatContext);
  const { id } = useParams<{ id: string }>();
  const { user } = useContext(UserAccessContext.Context);
  const [dataSource, setDataSource] = useState<Paginator<Message>>();
  const [channel, setChannel] = useState<Conversation | undefined>(undefined);
  const [participants, setParticipants] = useState<Participant[] | undefined>(
    [],
  );
  const [loading, setLoading] = useState(false);
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();

  async function onLoadMoreData() {
    if (!dataSource?.hasPrevPage && !dataSource?.items) return [];

    const nextPage = await dataSource.prevPage();

    setDataSource((prev) => ({
      ...nextPage,
      items: [...nextPage.items, ...(prev?.items || [])],
    }));

    return [...nextPage.items, ...dataSource.items];
  }

  const getChannel = useCallback(async () => {
    setLoading(true);

    const channelData = await getChannelById(id);

    await channelData?.setAllMessagesRead();

    setParticipants(await channelData?.getParticipants());

    setChannel(channelData);

    const messages = await channelData?.getMessages(30).finally(() => {
      setLoading(false);
    });

    if (!messages) {
      console.error(
        'Something went wrong when getting messages for this channel.',
      );
    } else setDataSource(messages);
  }, [getChannelById, id]);

  useEffect(() => {
    getChannel();
  }, [getChannel]);

  useEffect(() => {
    if (channel && user) {
      channel.on('messageAdded', async (message) => {
        if (id === message.conversation.sid) {
          if (user.id.toString() !== message.author?.toString()) {
            setDataSource((prev) => {
              if (prev) {
                return {
                  ...prev,
                  items: prev.items.concat([message]),
                };
              }
              return prev;
            });
          }

          if (message.author?.toString() === user.id.toString()) {
            // Remove optimistic  message in favor of actual message
            setDataSource((prev) => {
              if (prev) {
                const filteredMessages = prev?.items.map((item) => {
                  if (
                    message.author === item.author &&
                    message.body === item.body &&
                    (item.index === -1 || item.index === message.index)
                  )
                    return message;

                  return item;
                });

                return {
                  ...prev,
                  items: filteredMessages,
                };
              }
              return prev;
            });
          }
        }
      });
    }

    return () => {
      if (channel) {
        channel.removeAllListeners('messageAdded');
      }
    };
  }, [id, channel, user]);

  useEffect(() => {
    if (participants) {
      participants.map((participant) =>
        participant.on(
          'updated',
          ({ participant: updatedParticipant, updateReasons }) => {
            const reason = updateReasons.includes('lastReadMessageIndex');
            if (reason) {
              setParticipants((prev) =>
                prev?.map((outdatedParticipant) => {
                  if (participant.identity === outdatedParticipant.identity) {
                    return updatedParticipant;
                  }
                  return outdatedParticipant;
                }),
              );
            }
          },
        ),
      );
    }
    return () => {
      participants?.map((item) => item.removeAllListeners());
    };
  }, [participants]);

  return (
    <ConfigProvider theme={THEME}>
      <Card
        style={{
          marginRight: SPACING.SIZE_MD,
          marginBottom: SPACING.SIZE_MD,
        }}
        title={
          <ConfigProvider theme={CARD_TITLE_THEME}>
            <Flex align="center" gap={SPACING.SIZE_MS}>
              {xs ? <Button icon={<LeftOutlined />} ghost type="text" /> : null}
              <Skeleton
                loading={loading}
                active
                title={false}
                paragraph={{
                  rows: 1,
                  style: {
                    fontSize: FONTS.large.size,
                    lineHeight: FONTS.large.lineHeight,
                    width: 300,
                  },
                }}
              >
                <Typography.Text
                  style={{
                    fontWeight: 500,
                  }}
                >
                  {channel?.friendlyName}
                </Typography.Text>
              </Skeleton>
            </Flex>
          </ConfigProvider>
        }
      >
        <Flex vertical style={LIST_STYLE} id="scroll">
          {loading ? (
            <Spin indicator={<Loading3QuartersOutlined spin />} />
          ) : (
            <InfiniteScroll
              next={onLoadMoreData}
              hasMore={dataSource?.hasPrevPage || false}
              key="sid"
              loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
              dataLength={dataSource?.items.length || 0}
              scrollableTarget="scroll"
              pullDownToRefreshThreshold={100}
              initialScrollY={700}
              style={{
                display: 'flex',
                flexDirection: 'column-reverse',
              }}
              inverse
            >
              <List
                style={{
                  overflow: 'hidden',
                }}
                itemLayout="horizontal"
                dataSource={dataSource?.items}
                rowKey={(item) => item.sid}
                renderItem={(item, index) =>
                  RenderItem({
                    item,
                    employees,
                    index,
                    array: dataSource?.items,
                    participants,
                  })
                }
                split={false}
                id="list"
              />
            </InfiniteScroll>
          )}
        </Flex>
        <MessageInput channel={channel} setDataSource={setDataSource} />
      </Card>
    </ConfigProvider>
  );
}

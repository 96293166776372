import firebase from "firebase/app";
import "firebase/messaging";
import config from "config";

export const firebaseConfiguration = {
  apiKey: config.REACT_APP_FIREBASE_API_KEY,
  messagingSenderId: config.REACT_APP_FIREBASE_SENDER_ID,
  projectId: config.REACT_APP_FIREBASE_PROJECT_ID,
  appId: config.REACT_APP_FIREBASE_APP_ID
};
firebase.initializeApp(firebaseConfiguration);

export default firebase;

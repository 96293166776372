import React from 'react';
import { Row, Col, Grid } from '@optii-solutions/ui-library';
import { Channels } from '../channels';
import { MessageList } from './List';
import { SearchAndCreate } from '../search/SearchAndCreate';

export function Messages() {
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  return (
    <Row>
      {xs ? null : (
        <Col xs={24} sm={10} md={8} lg={6}>
          <Channels />
        </Col>
      )}
      <Col xs={24} sm={14} md={16} lg={18}>
        {!xs ? <SearchAndCreate /> : null}
        <MessageList />
      </Col>
    </Row>
  );
}

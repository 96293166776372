import React, { CSSProperties } from 'react';
import {
  ConfigProvider,
  ThemeConfig,
  Badge,
  COLORS,
  Avatar,
  Button,
  Typography,
  FONTS,
} from '@optii-solutions/ui-library';
import { Client, Conversation } from '@twilio/conversations';
import { useOnlineStatus } from '../../hooks/useOnlineStatus';

const THEME: ThemeConfig = {
  components: {
    Button: {},
  },
};
const EMPLOYEE_NAME_STYLE: CSSProperties = {
  fontSize: FONTS.medium.size,
  letterSpacing: FONTS.medium.letterSpacing,
};

type EmployeeOptionProps = {
  label: string;
  value: string;
  client: Client | null;
  onCreateNewChannel: (userId: string) => Promise<Conversation | undefined>;
};

export function EmployeeOption({
  label,
  value,
  client,
  onCreateNewChannel,
}: EmployeeOptionProps) {
  const { status } = useOnlineStatus(client, value);

  return (
    <ConfigProvider theme={THEME}>
      <Button
        type="text"
        style={{
          width: '100%',
          justifyContent: 'flex-start',
        }}
        onClick={async () => onCreateNewChannel(value)}
        icon={
          <Badge dot={status} color={COLORS.polarGreen[5]} offset={[-4, 22]}>
            <Avatar
              style={{
                verticalAlign: 'center',
              }}
              size="small"
              gap={4}
            >
              {label.charAt(0).toUpperCase()}
            </Avatar>
          </Badge>
        }
      >
        <Typography.Text style={EMPLOYEE_NAME_STYLE}>{label}</Typography.Text>
      </Button>
    </ConfigProvider>
  );
}

import { useQuery } from '@apollo/client';

import { GET_LIST_LOCATION_MINIMAL_LOCATIONS } from '@optii/shared/queries/getListLocationMinimal';

/**
 * Hook for using GET_LIST_LOCATION_MINIMAL_LOCATIONS query.
 * @param {Object} props - Settings for the hook
 * @param {boolean} [props.skip=false] - Should skip or not the fetch.
 * @param {boolean} [props.withShortDisplayName=false].
 * @param {boolean} [props.withLongDisplayName=false].
 * @returns {UseMinimalLocationsReturn}
 */
export function useMinimalLocations({
  skip = false,
  withShortDisplayName = false,
  withLongDisplayName = false,
} = {}) {
  const { data, loading, error } = useQuery(
    GET_LIST_LOCATION_MINIMAL_LOCATIONS,
    {
      skip,
    }
  );

  const getCurrentTitle = ({
    withShortDisplayName: _withShortDisplayName,
    withLongDisplayName: _withLongDisplayName,
    location,
  }: any) => {
    if (_withShortDisplayName) {
      return location.shortDisplayName;
    } if (_withLongDisplayName) {
      return location.longDisplayName;
    } 
      return location.title;
    
  };

  const childrenLocations = data?.ListLocationMinimal?.map((location) => {
    const isParent = location.childCount > 0;

    const title = getCurrentTitle({
      withShortDisplayName,
      withLongDisplayName,
      location,
    });

    const locationId = isParent ? location.id + 0.1 : location.id;
    return {
      ...location,
      pId: Number(location.pId + 0.1),
      id: locationId,
      value: locationId,
      title,
      displayName: location.name,
      name: location.name,
      shortDisplayName: location.shortDisplayName,
      longDisplayName: location.longDisplayName,
      locationTypeName: location.locationTypeName,
      roomType: location.roomTypeName,
    };
  }).sort((a, b) => a.title.localeCompare(b.title, undefined, {
      numeric: true,
      sensitivity: 'base',
    }));

  const parentLocations = data?.ListLocationMinimal?.filter(
    (location) => location.childCount > 0
  ).map((parentLocation) => ({
    ...parentLocation,
    pId: Number(parentLocation.id + 0.1),
    id: parentLocation.id,
    value: parentLocation.id,
    title: getCurrentTitle({
      withShortDisplayName,
      withLongDisplayName,
      location: parentLocation,
    }),
    displayName: parentLocation.name,
    shortDisplayName: parentLocation.shortDisplayName,
    longDisplayName: parentLocation.longDisplayName,
    name: parentLocation.name,
    locationTypeName: parentLocation.locationTypeName,
    roomType: parentLocation.roomTypeName,
  }));
  const formattedLocations = parentLocations?.concat(childrenLocations);

  return {
    locations: formattedLocations,
    loading,
    error,
  };
}

import { Button, Typography } from '@optii-solutions/ui-library';
import { MouseEventHandler, ReactNode } from 'react';

type ButtonProps = {
  onClick?: MouseEventHandler<HTMLElement> | undefined;
  children: ReactNode;
};

function ViewJobButton({ onClick, children }: ButtonProps) {
  return (
    <Button type="default" onClick={onClick}>
      {children}
    </Button>
  );
}

const PATTERN = /\[\[([^\]]+)\]\]/g;

export default function handleMessageContent(message: string | null) {
  const formatMessage = [];
  let lastIndex = 0;

  let match;

  if (message) {
    while ((match = PATTERN.exec(message))) {
      const startIndex = match.index;
      const beforeMatchText = message.substring(lastIndex, startIndex);
      const mentionSplitText = match[1].split('@')[1];

      if (beforeMatchText) {
        formatMessage.push(beforeMatchText);
      }

      formatMessage.push(
        <Typography.Link key={lastIndex}>
          {' '}
          @{mentionSplitText}{' '}
        </Typography.Link>,
      );
      lastIndex = PATTERN.lastIndex;
    }

    const remainingText = message?.substring(lastIndex);

    if (remainingText) {
      formatMessage.push(remainingText);
    }

    if (message?.includes('Job #')) {
      const id = message.split('#')[1];
      const extractText = (formatMessage[0] as string).split('#')[0];

      formatMessage.shift();
      formatMessage.push(extractText);
      formatMessage.push(
        <ViewJobButton onClick={() => console.log('ID', id)}>
          #{id}
        </ViewJobButton>,
      );
    }

    return formatMessage;
  }
  return message;
}

import { SendOutlined } from '@ant-design/icons';
import {
  Button,
  COLORS,
  ConfigProvider,
  Flex,
  Mentions,
  RADIUS,
  SPACING,
  ThemeConfig,
} from '@optii-solutions/ui-library';
import GoogleAnalyticsClient from '@optii/shared/utils/GoogleAnalyticsClient';
import {
  Dispatch,
  KeyboardEvent,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import {
  Conversation,
  JSONValue,
  Message,
  Paginator,
} from '@twilio/conversations';
import { GA_EVENTS, UserAccessContext } from '@optii/shared';
import { ChatContext } from '../../context/chat.context';
import { CHANNEL_TYPES, LANGUAGES } from '../../constants';
import { FileList } from '../../types';
import { UploadFile } from './UploadFIle';
import { InputFilePreview } from './InputFilePreview';

type TaggedUser = {
  label: string;
  value: string;
  key: string;
};

type MessageInputProps = {
  channel: Conversation | undefined;
  setDataSource: Dispatch<SetStateAction<Paginator<Message> | undefined>>;
};

const PRIVATE_CHANNEL_PREFIX = 'ªº'; // Disables Mentions for private channels

function formatBody(
  message: string,
  taggedUsers: TaggedUser[],
  t: TFunction<['chat', 'common']>,
) {
  let formattedMessage = message;

  taggedUsers.forEach(({ label, key }) => {
    const mention =
      label !== t('chat:Channel') ? `[[${key}@${label}]]` : '[[@Channel]]';

    const REGEX = new RegExp(`@${label}(?!\\S)`, 'g');

    formattedMessage = formattedMessage.replace(REGEX, mention);
  });

  return formattedMessage;
}

export function MessageInput({ channel, setDataSource }: MessageInputProps) {
  const { t } = useTranslation(['chat', 'common']);
  const isPrivateChannel =
    (channel?.attributes as { ChannelType: string })?.ChannelType !==
    CHANNEL_TYPES.public;
  const { user } = useContext(UserAccessContext.Context);
  const { employees } = useContext(ChatContext);
  const [value, setValue] = useState<string | undefined>('');
  const [taggedUsers, setTaggedUsers] = useState<Array<TaggedUser>>([]);
  const [fileList, setFileList] = useState<FileList[]>([]);

  function filterTaggedUsers(input: string) {
    setTaggedUsers((prev) =>
      prev.filter((label) => {
        const userLabelMention = `@${label}`;

        return input.includes(userLabelMention);
      }),
    );
  }

  const THEME: ThemeConfig = {
    components: {
      Input: {
        borderRadiusLG: RADIUS.RADIUS_L,
        sizeXL: 40,
      },
      Mentions: {
        paddingInline: 16,
        paddingBlock: 12,
      },
      Button: {
        colorPrimary: COLORS.secondary[5],
        colorPrimaryActive: COLORS.secondary[6],
        colorPrimaryHover: COLORS.secondary[6],
      },
    },
  };

  function generateAttributes() {
    const language = LANGUAGES.find((item) => item.id === user?.preferredLang);
    const sender = {
      id: user?.id,
      userFirstName: user?.firstName,
      userLastName: user?.lastName,
      language,
    };

    const attachments = fileList.map(({ name, url }) => ({
      name,
      url,
    }));

    if (isPrivateChannel) {
      const recipientId = channel?.uniqueName
        ?.split('_')
        .find((id) => id !== user?.id);
      return {
        sender,
        channel: {
          type: CHANNEL_TYPES.private,
        },
        attachments,
        receiver: [{ id: recipientId }],
      };
    }
    return {
      sender,
      attachments,
      channel: {
        type: CHANNEL_TYPES.public,
      },
    };
  }

  const onSendMessage = async () => {
    if (!channel || !value) {
      return;
    }

    filterTaggedUsers(value);

    const formattedMessageBody = formatBody(value, taggedUsers, t);

    const messageBuilder = channel
      .prepareMessage()
      .setBody(formattedMessageBody);

    setValue(undefined);

    if (taggedUsers.length > 0) GoogleAnalyticsClient.event(GA_EVENTS.tagUser);

    const attributes = generateAttributes();

    messageBuilder.setAttributes(attributes as JSONValue);

    const optimisticResponse = {
      body: messageBuilder.build().text,
      author: attributes.sender.id,
      dateCreated: new Date(),
      dateUpdated: new Date(),
      index: -1,
      sid: messageBuilder.build().contentSid,
      attributes,
    };
    setDataSource((prev) => {
      if (prev) {
        return {
          ...prev,
          items: prev.items.concat([optimisticResponse as unknown as Message]),
        };
      }

      return prev;
    });
    setTimeout(() => {
      const list = document.getElementById('scroll');
      if (list) {
        list.scrollTop = list.scrollHeight;
      }
    }, 10);

    const sentMessageIndex = await messageBuilder.buildAndSend();

    try {
      await channel.advanceLastReadMessageIndex(sentMessageIndex ?? 0);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const onChange = (input: string) => {
    if (channel) channel.typing();
    setValue(input);

    if (!input) {
      setTaggedUsers([]);
    }
  };

  const onKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    const { key } = e;
    const isMentionsPopupOpen =
      document.getElementsByClassName('message-input-popup').length > 0;

    if (key === 'Enter' && !e.shiftKey && !isMentionsPopupOpen) {
      e.preventDefault();
      e.stopPropagation();
      onSendMessage();
    }
  };

  const mentionOptions =
    useMemo(
      () =>
        [
          {
            value: t('chat:Channel'),
            label: t('chat:Channel'),
            key: 'channel',
          },
        ].concat(
          employees.map((employee) => ({
            value: employee.label,
            label: employee.label,
            key: employee.value,
          })),
        ),
      [employees, t],
    ) || [];

  return (
    <ConfigProvider theme={THEME}>
      <InputFilePreview fileList={fileList} setFileList={setFileList} />
      <Flex
        gap={SPACING.SIZE_DEFAULT}
        style={{
          position: 'relative',
        }}
        align="center"
      >
        <UploadFile setFileList={setFileList} />

        <Mentions
          placeholder={t('chat:Message {{channelName}}', {
            channelName: 'Placeholder',
          })}
          popupClassName="message-input-popup"
          filterOption={(input, item) => {
            const { label } = item as { label: string };

            return label
              .toLowerCase()
              .trim()
              .includes(input.trim().toLowerCase());
          }}
          options={mentionOptions}
          value={value}
          key="key"
          prefix={isPrivateChannel ? PRIVATE_CHANNEL_PREFIX : '@'}
          styles={{
            textarea: {
              minHeight: 48,
              paddingRight: 48,
            },
          }}
          autoSize={{
            maxRows: 6,
          }}
          onSelect={(option) => taggedUsers.push(option as TaggedUser)}
          onKeyDown={onKeyDown}
          onChange={onChange}
        />
        <Button
          type="primary"
          icon={<SendOutlined />}
          onClick={onSendMessage}
          style={{
            position: 'absolute',
            right: '0',
            top: 0,
            transform: 'translate(-8px, 8px)',
          }}
        />
      </Flex>
    </ConfigProvider>
  );
}

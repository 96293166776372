import {
  Col,
  COLORS,
  ConfigProvider,
  MenuProps,
  Row,
  SPACING,
  Badge,
  Avatar,
  FONTS,
  Typography,
  Flex,
  RADIUS,
} from '@optii-solutions/ui-library';
import { CSSProperties } from 'react';
import { Link } from 'react-router-dom';

import { Channel } from '../../types';

type MenuItem = Required<MenuProps>['items'][number];
const MESSAGE_PREVIEW_ITEM_STYLE: CSSProperties = {
  height: 70,
  paddingInline: SPACING.SIZE_MD,
  borderBottom: `1px solid ${COLORS.neutral[5]}`,
  borderRadius: RADIUS.NONE,
};

function AvatarWithBadge({
  namePreview,
  status,
}: {
  namePreview?: string;
  status?: boolean;
}) {
  return (
    <ConfigProvider
      theme={{
        components: {
          Badge: {
            dotSize: SPACING.SIZE_DEFAULT,
          },
        },
      }}
    >
      <Badge
        dot={status}
        color={COLORS.polarGreen[5]}
        offset={[-4, 32]}
        styles={{
          indicator: {
            boxShadow: '0 0 0 2px #FFFF',
          },
        }}
      >
        <Avatar
          size={{
            lg: 40,
            xs: 36,
          }}
        >
          {namePreview?.toUpperCase()}
        </Avatar>
      </Badge>
    </ConfigProvider>
  );
}

function MessageContent({
  label: author,
  lastMessage,
  unreadMessageCount,
}: Omit<Channel, 'isOptii' | 'isOnline' | 'key'>) {
  const content = lastMessage?.content;
  const timestamp = lastMessage?.timestamp;
  return (
    <>
      <Row justify="space-between" align="middle" wrap={false}>
        <Typography.Text
          style={{
            color: COLORS.neutral[9],
          }}
          strong
          ellipsis
        >
          {author}
        </Typography.Text>
        <ConfigProvider
          theme={{
            components: {
              Typography: {
                fontSize: FONTS.xSmall.size,
                colorText: COLORS.neutral[7],
              },
            },
          }}
        >
          <Flex gap={SPACING.SIZE_DEFAULT}>
            <Typography.Text>{timestamp?.format('LL')}</Typography.Text>
            {unreadMessageCount ? (
              <Badge
                count={unreadMessageCount}
                color={COLORS.goldenPurple[5]}
                styles={{
                  indicator: {
                    paddingInline: SPACING.SIZE_DEFAULT,
                  },
                }}
              />
            ) : null}
          </Flex>
        </ConfigProvider>
      </Row>
      <Row>
        <Typography.Text
          ellipsis
          style={{
            color: COLORS.neutral[8],
          }}
        >
          {content}
        </Typography.Text>
      </Row>
    </>
  );
}

export function MessagePreview(
  { label: author, lastMessage, key }: Channel,
  isOnline?: boolean,
  unreadMessageCount?: number,
): MenuItem {
  const senderFirstLetter = author?.charAt(0);

  return {
    key,
    label: (
      <Link to={`/chat/${key}`}>
        <Row align="middle" wrap={false} gutter={SPACING.SIZE_DEFAULT}>
          <Col>
            <AvatarWithBadge
              namePreview={senderFirstLetter}
              status={isOnline}
            />
          </Col>
          <Col flex="auto">
            <ConfigProvider
              theme={{
                components: {
                  Typography: {
                    fontSize: FONTS.small.size,
                  },
                },
              }}
            >
              <MessageContent
                label={author}
                lastMessage={lastMessage}
                unreadMessageCount={unreadMessageCount}
              />
            </ConfigProvider>
          </Col>
        </Row>
      </Link>
    ),
    style: MESSAGE_PREVIEW_ITEM_STYLE,
  };
}

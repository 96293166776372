import { PropertyContext, UserAccessContext } from '@optii/shared';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import FingerprintJs from '@fingerprintjs/fingerprintjs';
import { Auth } from '@optii/shared/components/organisms/Authentication/AuthService';
import { Client, Message } from '@twilio/conversations';
import { useCreateTwilioTokenMutation } from '../api/twilio';
import { UnreadConversations } from '../types';
import { getAllConversationList } from '../utils/message';

type Role = {
  id: string;
  permissions: [string];
};

type Property = {
  Name: string;
  Roles: Role[];
  displayName: string;
  id: string;
  userId: string;
};

const GENERAL_CHANNEL_UNIQUE_NAME = '[-1] All Team Members';

async function getUnreadMessagesCount(
  client: Client,
  setUnreadConversations: Dispatch<SetStateAction<UnreadConversations>>,
) {
  if (!client) return;

  try {
    let total = 0;
    const channels: { [key: string]: number } = {};
    const subscribedConversations = await client.getSubscribedConversations();

    const allConversations = await getAllConversationList(
      subscribedConversations,
      subscribedConversations.hasNextPage,
    );

    const channelUnreadDataCount = await Promise.all(
      allConversations
        .filter((item) => item.uniqueName)
        .map(async (channelConversation) => {
          const channel = await client.getConversationBySid(
            channelConversation.sid,
          );
          const unreadChannelMessageCount =
            await channel.getUnreadMessagesCount();

          return {
            sid: channelConversation.sid,
            unreadChannelMessageCount,
          };
        }),
    );

    channelUnreadDataCount.forEach(({ sid, unreadChannelMessageCount }) => {
      channels[sid] = unreadChannelMessageCount || 0;
      total += unreadChannelMessageCount || 0;
    });

    setUnreadConversations({
      channels,
      total,
    });
  } catch (error) {
    console.error('ERROR', error);
  }
}

async function updateUnreadConversations(
  message: Message,
  setUnreadConversations: Dispatch<SetStateAction<UnreadConversations>>,
  userId?: string,
) {
  if (userId === message.author) return;

  try {
    setUnreadConversations((unreadConversations) => {
      const total = Number(unreadConversations.total + 1);
      const channels = Object.keys(unreadConversations.channels).reduce(
        (acc: { [key: string]: number }, sid) => {
          if (sid === message.conversation.sid) {
            acc[sid] = unreadConversations.channels[sid] + 1;
            return acc;
          }
          acc[sid] = unreadConversations.channels[sid];
          return acc;
        },
        {},
      );

      return {
        ...unreadConversations,
        ...{
          total,
          channels,
        },
      };
    });
  } catch (error) {
    console.error('ERROR', error);
  }
}

export default function useTwilio() {
  const { user } = useContext(UserAccessContext.Context);
  const { property } = useContext(PropertyContext.Context) as {
    property: Property;
  };

  const [deviceId, setDeviceId] = useState(
    localStorage.getItem('deviceID') || '',
  );
  const [client, setClient] = useState<Client | null>(null);
  const [generalChannelId, setGeneralChannelId] = useState<string>('');
  const [unreadConversations, setUnreadConversations] =
    useState<UnreadConversations>({
      total: 0,
      channels: {},
    });

  const [createTwilioToken] = useCreateTwilioTokenMutation({
    onError(error) {
      console.error(
        'Unable to Create Twilio Token - Ensure Chat is enabled for this property',
        error,
      );
    },
  });

  const createClient = useCallback(async () => {
    if (
      user &&
      property &&
      Auth.Instance.isTokenValid(0) &&
      (!client || client.user.identity !== user.id)
    ) {
      await createTwilioToken({
        variables: {
          input: {
            device: deviceId,
            identity: user.id,
          },
        },
        onCompleted({ createTwilioToken: { token } }) {
          if (client) {
            client.removeAllListeners();
            client.updateToken(token);
          }
          if (!client) {
            const newClient = new Client(token);

            newClient.on('stateChanged', (state) => {
              if (state === 'initialized') {
                setClient(newClient);
              } else {
                console.error(`State ${state}`);
              }
            });
          }
        },
        onError(error) {
          console.error('Error creating the client', error);
        },
      });
    }
  }, [client, createTwilioToken, deviceId, property, user]);

  async function getFingerprint() {
    if (deviceId) return '';
    const fingerprint = await FingerprintJs.load();
    const { visitorId } = await fingerprint.get();

    localStorage.setItem('deviceID', visitorId);
    setDeviceId(visitorId);

    return '';
  }

  requestIdleCallback(() => {
    getFingerprint();
  });

  useEffect(() => {
    if (!client) {
      (async () => {
        await createClient();
      })();
    }
    if (client) {
      (async () => {
        await getUnreadMessagesCount(client, setUnreadConversations);

        client.on('messageAdded', (message) => {
          // Get the current channel ID
          const path = window.location.pathname;
          const [, , id] = path.split('/');

          if (id !== message.conversation.sid) {
            updateUnreadConversations(
              message,
              setUnreadConversations,
              user?.id,
            );
          }
        });

        const generalChannel = await client.getConversationByUniqueName(
          GENERAL_CHANNEL_UNIQUE_NAME,
        );

        setGeneralChannelId(generalChannel.sid);

        client.on('tokenAboutToExpire', async () => {
          if (user) {
            await createTwilioToken({
              variables: {
                input: {
                  device: deviceId,
                  identity: user?.id.toString(),
                },
              },
              async onCompleted({ createTwilioToken: { token } }) {
                await client.updateToken(token);
              },
              onError(error) {
                console.error('Error updating the twilio token', error);
              },
            });
          }
        });
      })();
    }
  }, [client, createTwilioToken, deviceId, user, createClient]);

  return {
    client,
    unreadConversations,
    updateUnreadConversations,
    setUnreadConversations,
    generalChannelId,
  };
}
